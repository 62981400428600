@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* fix Mui Table Pagination component margins issue by bootstrap */
.MuiTablePagination-root p {
  margin: 0px;
}
/* remove autofill colors on chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}
/* .tox-tinymce-aux {
  display: none !important;
} */
.tox-tinymce-aux .tox-notifications-container {
  display: none !important;
}

.custom-badge {
  background-color: #4c50af; /* Set your desired background color */
  color: white; /* Text color */
  font-size: 7px; /* Adjust badge font size */
  width: auto; /* You can adjust width as needed */
  /* padding:  0px;
  margin: 0px;
  border-radius: 0; */
}

.badge-text {
}
.col_setting_and_100_at_print_screen {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
/* This style will hide elements with class "navbar" when printing */
@media print {
  .hide_when_print {
    display: none;
  }
  /* .css-zkwcd7-MuiButtonBase-root-MuiIconButton-root {
    display: none;
  } */
  .col_setting_and_100_at_print_screen {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.student_history_table {
  margin-bottom: 60px;
}
.student_history_table_head_cell {
  background-color: #012149;
  color: #fff;
}

/* CSS for Webkit-based browsers */
.custom-scrollbar {
  scrollbar-width: thin; /* Set scrollbar size for Firefox */
  scrollbar-color: #3d83ec; /* Set scrollbar color for Firefox */
  overflow: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3d83ec; /* Set the thumb (handle) color */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #0c2e60; /* Set the track color */
}

.on_click_text {
  cursor: pointer;
  /* font-weight: 500; */
  color: #000;
  /* animation: scaleAnimation 3s alternate infinite; */
}

.on_click_text:hover {
  /* animation-play-state: paused;   */
  /* animation: scaleAnimation 0.3s alternate infinite; */
  color: #00b2c5;
  transform: scale(1.1);
  /* transition: transform 0.3s; */
  transition: transform 0.3s, color 0.3s;
  /* font-weight: 700;  */
  /* display: inline-block;   */

}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.detailBackgroundColor{
  background-color: #fafcfb;
}
.detailBackgroundColor2{
  background-color: #00b259;
}

.no-access-string {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  /* height: 50vh; */
  height: 90vh;
  font-weight: 500;
}











.small-contained-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.775em;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 34px;
  padding: 6px 16px;
  border-radius: 0px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* color: rgb(255, 255, 255);
  background-color: rgb(25, 97, 222); */
  color: rgb(25, 97, 222);
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(25 97 222 / 24%) 0px 8px 16p;
  margin-top: -10px;
}

.small-contained-button-calender {
  background-color: transparent;
  color: #0071cf;
  padding: 7px 10px!important;
  border: 1px solid #0071cf!important;
}

.small-contained-button-calender:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.small-contained-button-calender:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
 
.small-contained-button-calender:hover{

  background-color: #0071cf;
  color: #ffffff;
  /* border-color: #ee1400; */
  border: 1px solid #0071cf!important;
  /* margin: 0px 2px; */
  }    

  /* Heading start */
  .title_heading_in_all_pages_top_left{

  }  
  /* Heading end */
  .container_in_all_pages{
    padding-left: 50px!important;
    padding-right: 50px!important;
  }
  .tox-statusbar__branding{
    display: none;
  }
  .tox-statusbar__path-item{
    display: none;
  }

  .view-link {
    font-size: 14px;
  }
  .lesson-activity {
    display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    margin-bottom: 20px;
  }
  .views-lesson {
    /* margin-left: 10px; */
  }
   
  
  .views-lesson h5 {
    color: #808080;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
    }
    
    .views-lesson h4 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    }
    .Assignment_heading{
      font-size: 1.4rem;
      margin-top: 0px;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.0;
     
      color: #333; /* Optional: Adjust the text color */
     
      }
     
    
      .batch_info_heading {
      /* color: #808080; */
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      }
      
      .batch_info_sub_text {
      /* color: #000; */
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 2px;
      }
      .assignment_sub_heading_in_add_edit{
        font-weight: 600;
      }
      .button_tax{
        /* background-color: #dfe3e8!important; */
        border:none!important;
        color:#000!important;
        box-shadow: none!important;
      }
      .button_tax1:focus{
        background-color: red!important;
        border:none;
        box-shadow: none!important;
      }
      .button_tax1:hover{
        background-color: red!important;
        border:none;
        box-shadow: none!important;
      }

      .btn-check:focus + .btn, .btn:focus {
        color: var(--bs-btn-hover-color);
        background-color: var(--bs-btn-hover-bg);
        border-color: var(--bs-btn-hover-border-color);
        outline: 0;
        box-shadow: none!important;
      }

      .all-amount-cards1 {
        color: #000;
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          background-image: none;
          overflow: hidden;
          /* position: relative; */
          z-index: 0;
          box-shadow: rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 5px 8px 0px, rgba(145, 158, 171, 0.12) 0px 1px 14px 0px;
          /* text-align: center; */
          /* height: 100px; */
        /* height: 20vh; */
          border-radius: 8px;
        padding: 30px;
        }

        .all-amount-cards {
        color: #000;
        border-radius: 15px;
        padding: 10px;
        box-shadow: rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 5px 8px 0px, rgba(145, 158, 171, 0.12) 0px 1px 14px 0px;
   
        }
.fc-prev-button{
  display: none;
}
.fc-next-button{
  display: none;
}
.fc-prev-button .fc-button .fc-button-primary{
  display: none!important;
}
.fc-button-group{
  display: none!important;
}























/* ResponsiveCalendar.css */

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  padding: 20px;
  background-color: #f5f5f5;
}

.calendar-header {
  text-align: center;
  margin-bottom: 20px;
}

.day-cell {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;
}

.events {
  margin-top: 8px;
}

.event {
  background-color: #007bff;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  margin-bottom: 4px;
}

.centered-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin-top: 15vh;
  margin-left: 120px;
  margin-right: 120px;
  z-index: 9999!important; /* Set z-index to 9999 */
}
 
/* .centered-modal {
  display: grid;
  place-items: center;
  min-height: 100vh;
} */
 

/* month */
.fc .fc-toolbar-title {
  font-size: 1.75em;
  margin: 0px;
  color: #be1000;
  display: none;
}

.fc-direction-ltr .fc-button-group > .fc-button {
  background-color: transparent;
 
  color: #ee1400;
  border-color: #ee1400;
  display: none;
}
.fc .fc-button-group > .fc-button.fc-button-active, .fc .fc-button-group > .fc-button:active, .fc .fc-button-group > .fc-button:focus, .fc .fc-button-group > .fc-button:hover {
  z-index: 1;
  background-color: #990f02;
  color: #ffffff;
  display: none;
  /* box-shadow: none; */
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
  /* box-shadow: rgba(76, 91, 106, 0.5) 0px 0px 0px 0.2rem; */
  box-shadow: none;
  display: none;
}

.fc .fc-button:focus {
  /* box-shadow: rgba(44, 62, 80, 0.25) 0px 0px 0px 0.2rem; */
  box-shadow: none;
  display: none;
  /* outline: 0px; */
}

 
.calendar-week-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
 


.small-contained-button-calender {
  background-color: transparent;
  color: #0071cf;
  padding: 7px 10px!important;
  border: 1px solid #0071cf!important;
}

.small-contained-button-calender:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.small-contained-button-calender:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
 
.small-contained-button-calender:hover{

  background-color: #0071cf;
  color: #ffffff;
  /* border-color: #ee1400; */
  border: 1px solid #0071cf!important;
  /* margin: 0px 2px; */
  }    




.selected-button{
  background-color: #ee1400;
color: #ffffff;
/* border-color: #ee1400; */
border: 1px solid #ee1400!important;
}
 
.event-title-color{
  /* color: #be1000!important; */
}
 
.calendar-setting{
  /* background-color: #d61301; */
/* color: #ffffff; */
/* border-color: #ee1400; */
/* border: 1px solid #71717184!important; */
}
.border-black{
  /* border : 1px solid black; */
}
.border-green{
  /* border : 1px solid green; */
}

.border-gray{
  border : 1px solid gray;
  border-radius: 10px;
 
}

 
.masonry-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}

.card {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
}

.tall {
  height: 300px;
}
.Calender-EventStyle{
  color: #2c2c2c;
  display: flex;
  align-items: center;
  font-size: 1em; /* Will be 16px (1 * 16px) */
}
.calendar-event-time-heading-outer{
  margin-bottom: 5px;
}
.calendar-event-heading{
  margin-bottom: 0px;
}
.calendar-time-heading{
  color: #2c2c2c99;
  font-size: 0.85em; /* Will be 16px (1 * 16px) */
}

.calendar-day-heading{
  margin-bottom: 0px;
}
.calendar-date-heading{
  color: #2c2c2c99;
  font-size: 0.85em; /* Will be 16px (1 * 16px) */
}
.cursor-pointer{
  cursor: pointer;
}
.Event-detail-popUpModel{
  z-index: 999999999;
}
.event-detail-highlight{
  font-weight: 600;
}

 
.fc-day {
  height: 20%!important;
  min-height: 20px!important;
  max-height: 20px!important;
    /* background: green!important; */
}
 
.fc-daygrid-day-frame{
  height: 20px!important;
  min-height: 20px!important;
  max-height: 20px!important;
} 

.fc-popover-header{
/* background: #f1f1f1; */
border: 1px solid #e3e3e3;
height:30px;
opacity: 1;
}
.fc-popover-body{
  background: white;
  border: 1px solid #e3e3e3;
}
.fc-popover-title{
  background: #f1f1f1;
  color:#616161;
  width: 100%;
  /* height:20px; */
  padding: 0px 10px;
}
.fc-popover-close{
  background: #f1f1f1;
}

.shadowBox {
  box-shadow: 0 2px 5px -1px rgba(50,50,93,.25),0 1px 3px -1px rgba(0,0,0,.3);
}

.shadowBox2 {
  box-shadow: 0 2px 4px -1px rgba(50,50,93,.25),0 1px 2px -1px rgba(0,0,0,.3);
}

.shadowBoxCenterHeading {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.28571rem;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(241, 243, 244);
  text-align: center;
  padding: 16px;
  color: rgb(33, 43, 54);
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: rgb(255, 255, 255);
}


 







.dash-widget1 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dash-widget2 {
border: none;
}
.dash-widget1 .circle-bar {
  text-align: center;
}
.dash-widget1 .circle-bar > div {
display: inline-block;
position: relative;
text-align: center;
}
.dash-widget1 .circle-bar > div b {
font-size: 24px;
font-weight: 600;
color: #b8b7ff;
  left: 0;
  position: absolute;
  top: 50%;
  right: 0;
  text-align: center;
  margin: 0 auto;
  transform: translateY(-50%);
}
.dash-widget1 .circle-bar > div canvas {
width: 90px !important;
height: 90px !important;
}
.dash-widget1 h6 {
font-size: 14px;
font-weight: 500;
/* color: #6e6bfa; */
/* margin-top: -20px; */
margin-bottom: 10px;
}
.dash-widget1 h4 {
font-size: 20px;
font-weight: 600;
/* color: #6e6bfa; */
margin: 0;
}
.dash-widget1 h4 span {
/* color: #b8b7ff; */
}
.dash-details {
padding-top: 0px;
}
.dash-details h4 {
font-size: 16px;
font-weight: 600;
color: #3b3b3b;
margin-bottom: 10px;
}
.dash-widget1 ul {
padding: 0;
margin: 0 -6px;
list-style: none;
}
.dash-widget1 ul li {
padding: 6px 6px;
display: inline-block;
font-size: 14px;
  font-weight: 500;
  color: #777;
}
.dash-widget1 .dash-btn {
margin-top: 30px;
}
.dash-widget1 .dash-btn .btn {
  font-size: 14px;
  font-weight: 500;
  width: 110px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #ffffff;
  background: #009efb;
}
.dash-widget1 .dash-btn .btn:last-child {
margin-right: 0;
}
.dash-widget1 .dash-btn .btn:hover {
color: #009efb;
  background: transparent;
}
.dash-widget1 .dash-btn .btn-border {
  color: #009efb;
  background: transparent;
}
.dash-widget1 .dash-btn .btn-border:hover {
color: #ffffff;
  background: #009efb;
} 

.flex-container {
  display: flex;
}

.left-div {
  flex: 0 0 auto; /* Flex-basis set to auto so it only takes the content size */
  /* Add styling for the left div */
}

.center-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* To occupy remaining space */
}

.center-div {
  /* Add styling for the center divs */
}

.border-card{
  border: 1px solid #c7cacd78;
}